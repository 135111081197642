import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import ptBR from "@/locales/pt-BR";
import en from "@/locales/en";

function loadJSLocaleMessages(): LocaleMessages<VueMessageType> {
  const messages: LocaleMessages<VueMessageType> = {};
  messages["pt-BR"] = ptBR;
  messages["en"] = en;
  return messages;
}

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || navigator.language,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadJSLocaleMessages(),
});
