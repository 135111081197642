const en = {
  intranet_eds: "EDS Intranet",
  neagle_bank: "Neagle Bank",
  others: "Others",
  footer: "All rights reserved.",
  about_the_project: "About the project",
  about: "About",
  technologies: "Technologies",
  back: "Back",
  generation_z: "Generation Z",
  home_h2: "Hello! My name is {name}, and I'm {age} years old.",
  home_p_1:
    "This site is intended to be my portfolio, made by me, so people can see some of my work and projects that I participated.",
  home_p_2:
    "I work as a Full Stack Developer in the market since 2013. I program and maintain software for web, mobile and desktop platforms.",
  home_p_3: "Contact:",
  home_p_4: "Social Networks",
  home_h3: "Projects",
  intranet_eds_p_1:
    "This project is an intranet from a private school {schoolName} in Cabo Frio - RJ. It started in September 2016, and I help keep it up today. I work as a developer and maintainer of the project.",
  intranet_eds_p_2:
    "The EDS Intranet application is an online platform that helps the school in different aspects.",
  intranet_eds_p_3:
    "In the administrative part, we facilitate the registration of students' enrollment, registration of classes and teachers. Everything is done via intranet, and is stored in a database, in the cloud.",
  intranet_eds_p_4:
    "Teachers access the system and publish the students' grades, which in turn can view the report with their grades and absences for each period and academic year. Everything is stored online for later access.",
  intranet_eds_p_5:
    "The person in charge of each student also receives access to the system, being able to view information such as the report card and occurrences of related students.",
  intranet_eds_p_6:
    "Furthermore, the application has a social networking module, where students and teachers interact on the platform, sharing links and study material.",
  neagle_bank_p_1:
    "I participated in the production of this app during my stay at {companyName} company. It started in 2019, and is currently available for download at {googlePlay} and {appStore} stores.",
  neagle_bank_p_2:
    "The NeagleBank app is a digital wallet made for {neagle} fans, who are {genZ} digital influencers.",
  neagle_bank_p_3:
    "The idea of the service is to allow children and teenagers to have a digital wallet, and to be able to move money without having to go through the bureaucracy of having an account in a conventional bank.",
  neagle_bank_p_4:
    "The main function of the application is to facilitate money movements in the digital world. The user can use the money within the app to pay slips, transfer to another user, and purchase game credit, all without leaving the app.",
  neagle_bank_p_5:
    "It also has a withdrawal functionality, in case you want to withdraw the money stored in the NeagleBank account.",
  neagle_bank_p_6:
    "Another cool feature is the Allowance. Made for children and teenagers to receive an allowance through the app. Parents are billed monthly, and if they choose to pay, the child receives it directly into the NeagleBank account.",
  neagle_bank_p_7:
    "There's also the {neagleCard}, which is a prepaid card that can be purchased by registering with the app and ordering. Any child or teenager can have their own Neagle card.",
  others_h2: "Other Projects",
  others_p_1:
    "Here are some projects I participated in, which mostly were discontinued over time.",
  others_h3_1: "This",
  others_p_2:
    "I made this site as a new version for my Portfolio, in just one night, and little by little I'm adding new content and functionality.",
  others_p_3:
    "I felt the need to redo my portfolio as the previous version was not very good visually, nor was it flexible enough to present each project in a unique and special way.",
  others_p_4:
    "Now in this current version, I intend to leave the style of each page wide open, so that I can customize the presentation of each project on the list.",
  others_p_5: "The site is hosted on {netlify}.",
  others_p_6: "Static website made for the BLOCKTRENDS event of the year 2019.",
  view_demo: "View Demo",
  design_by_bressan: "Website design by {bressan}.",
  others_p_7:
    "I made this site on a busy weekend, as it was the day before the event and it had to be done urgently.",
  others_p_8:
    "The BLOCKTRENDS event takes place annually, and the website was made to publicize the lectures and participants, and inform the date/time.",
  others_p_9:
    "The website made it easy to access the link for people to register for the event.",
  others_p_10:
    "We've included a Google map showing the exact location of the event.",
  others_h3_2: "QR Capital Asset Management",
  others_p_11: "Static website made for the company QR Capital, in 2019.",
  others_p_12:
    "This site was created in two weekends, urgently, for the company to comply with ANBIMA regulations.",
  others_p_13:
    "The website informs the company's services and introduces the team.",
  others_p_14:
    "It also has a contact area and a form for those interested in investment services.",
};

export default en;
