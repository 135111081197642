const ptBR = {
  intranet_eds: "Intranet EDS",
  neagle_bank: "Neagle Bank",
  others: "Outros",
  footer: "Todos os direitos reservados.",
  about_the_project: "Sobre o projeto",
  about: "Sobre",
  technologies: "Tecnologias",
  back: "Voltar",
  generation_z: "Geração Z",
  home_h2: "Olá! Sou o {name}, tenho {age} anos.",
  home_p_1:
    "Este site tem como finalidade ser meu portfólio, feito por mim, para as pessoas poderem ver alguns dos meus trabalhos e projetos que participei.",
  home_p_2:
    "Trabalho como Desenvolvedor Full Stack no mercado desde 2013. Programo e mantenho software para as plataformas web, mobile e desktop.",
  home_p_3: "Contato:",
  home_p_4: "Redes Sociais",
  home_h3: "Projetos",
  intranet_eds_p_1:
    "Este projeto é uma intranet de uma escola particular {schoolName}, de Cabo Frio - RJ. Teve início em Setembro 2016, e ajudo a manter até hoje. Trabalho como desenvolvedor e mantenedor do projeto.",
  intranet_eds_p_2:
    "A aplicação Intranet EDS é uma plataforma online que ajuda a escola em diversos aspectos.",
  intranet_eds_p_3:
    "Na parte administrativa, facilitamos o cadastro de matrícula dos alunos, cadastro de turmas e professores. Tudo é feito via intranet, e fica armazenado num banco de dados, na nuvem.",
  intranet_eds_p_4:
    "Os professores acessam o sistema e publicam as notas dos alunos, estes por sua vez podem visualizar o boletim com suas notas e faltas de cada período e ano letivo. Tudo fica armazenado online para acesso posterior.",
  intranet_eds_p_5:
    "O responsável de cada aluno também recebe um acesso ao sistema, podendo visualizar as informações como boletim e ocorrências dos alunos relacionados.",
  intranet_eds_p_6:
    "Além disso tudo, a aplicação tem um módulo de rede social, onde os alunos e professores interagem na plataforma, compartilhando links e material de estudo.",
  neagle_bank_p_1:
    "Participei da produção deste app durante minha estadia na empresa {companyName}. Teve início em 2019, e atualmente está disponível para download nas lojas {googlePlay} e {appStore}.",
  neagle_bank_p_2:
    "O aplicativo NeagleBank é uma carteira digital feita para os fãs dos {neagle}, que são influenciadores digitais da {genZ}.",
  neagle_bank_p_3:
    "A ideia do serviço é possibilitar crianças e adolescentes terem uma carteira digital, e poder movimentar dinheiro sem precisar passar pela burocracia de ter uma conta em um banco convencional.",
  neagle_bank_p_4:
    "A função principal do aplicativo é facilitar movimentações de dinheiro no mundo digital. O usuário pode usar o dinheiro dentro do aplicativo para fazer pagamento de boletos, transferir para outro usuário, e comprar crédito para jogos, tudo sem sair do app.",
  neagle_bank_p_5:
    "Também tem a funcionalidade de saque, caso queira resgatar o dinheiro guardado na conta NeagleBank.",
  neagle_bank_p_6:
    "Outra funcionalidade legal é a Mesada. Feito para crianças e adolescentes receberem mesada pelo aplicativo. Os pais recebem uma cobrança mensalmente, e se escolhe pagar, o filho recebe diretamente na conta NeagleBank.",
  neagle_bank_p_7:
    "Também tem o {neagleCard}, que é um cartão de pré-pago que pode ser adquirido ao se cadastrar no aplicativo e fazer o pedido. Qualquer criança ou adolescente pode ter seu próprio cartão dos Neagle.",
  others_h2: "Outros Projetos",
  others_p_1:
    "Aqui estão alguns projetos que participei, que em sua maioria, ao longo do tempo, foram descontinuados.",
  others_h3_1: "Este",
  others_p_2:
    "Fiz este site como uma versão nova para o meu Portfólio, em apenas uma noite, e aos poucos estou adicionando conteúdo e funcionalidades novas.",
  others_p_3:
    "Eu senti a necessidade de refazer meu portfólio, pois a vesão anterior não estava muito boa visualmente, nem flexível o suficiente para apresentar cada projeto de forma única e especial.",
  others_p_4:
    "Agora nessa versão atual, pretendo deixar bem aberto o estilo de cada página, para poder personalizar a apresentação de cada projeto da lista.",
  others_p_5: "O site está hospedado no {netlify}.",
  others_p_6: "Site estático feito para o evento BLOCKTRENDS do ano 2019.",
  view_demo: "Ver Demonstração",
  design_by_bressan: "Design do site feito por {bressan}.",
  others_p_7:
    "Fiz este site em um fim de semana corrido, pois era véspera do evento e teve de ser feito com urgência.",
  others_p_8:
    "O evento BLOCKTRENDS acontece anualmente, e o site foi feito para divulgar as palestras e participantes, e informar data/horário.",
  others_p_9:
    "O site facilitou o acesso ao link para as pessoas se inscreverem no evento.",
  others_p_10:
    "Incluímos um mapa do Google informando a localização exata do evento.",
  others_h3_2: "QR Capital Gestão",
  others_p_11: "Site estático feito para a empresa QR Capital, no ano 2019.",
  others_p_12:
    "Este site foi feito em dois fins de semana, com urgência, para a empresa atender uma regulamentação da ANBIMA.",
  others_p_13: "O site informa os serviços da empresa e apresenta a equipe.",
  others_p_14:
    "Também tem uma área de contato, e um formulário para interessados nos serviços de investimento.",
};

export default ptBR;
