<template>
  <div class="neagle-bank bg-gray-50">
    <div class="w-12 mx-auto my-2">
      <div class="flex justify-between">
        <img
          src="../../assets/svgs/br.svg"
          alt="Ver Página em Português do Brasil"
          class="h-4 cursor-pointer"
          @click="locale = 'pt-BR'"
        />
        <img
          src="../../assets/svgs/gb.svg"
          alt="View Page in English"
          class="h-4 cursor-pointer"
          @click="locale = 'en'"
        />
      </div>
    </div>
    <div class="card">
      <h2 class="text-2xl text-center text-gray-900 my-4">
        {{ t("neagle_bank") }}
      </h2>
      <div class="gallery">
        <img
          src="../../assets/images/neagle/screenshot_1.png"
          alt="Screenshot 1"
          class="gallery-item drag-none"
          @click="selectImage(1)"
        />
        <img
          src="../../assets/images/neagle/screenshot_2.png"
          alt="Screenshot 2"
          class="gallery-item drag-none"
          @click="selectImage(2)"
        />
        <img
          src="../../assets/images/neagle/screenshot_3.png"
          alt="Screenshot 3"
          class="gallery-item drag-none"
          @click="selectImage(3)"
        />
        <img
          src="../../assets/images/neagle/screenshot_4.png"
          alt="Screenshot 4"
          class="gallery-item drag-none"
          @click="selectImage(4)"
        />
        <img
          src="../../assets/images/neagle/screenshot_5.png"
          alt="Screenshot 5"
          class="gallery-item drag-none"
          @click="selectImage(5)"
        />
      </div>
      <i18n-t keypath="neagle_bank_p_1" tag="p" class="mt-2">
        <template #companyName>
          <a
            href="https://www.trampol.in/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Trampolin
          </a>
        </template>
        <template #googlePlay>
          <a
            href="https://play.google.com/store/apps/details?id=com.neaglebank"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Google Play
          </a>
        </template>
        <template #appStore>
          <a
            href="https://apps.apple.com/lv/app/neagle-bank/id1480105326"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            App Store
          </a>
        </template>
      </i18n-t>
    </div>
    <div class="card">
      <h3 class="text-xl text-center text-gray-900 my-4">
        {{ t("about_the_project") }}
      </h3>
      <i18n-t keypath="neagle_bank_p_2" tag="p" class="mt-2">
        <template #neagle>
          <a
            href="https://www.youtube.com/channel/UCNrthyyJVjrJUpePrl6lW_g"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Neagle
          </a>
        </template>
        <template #genZ>
          <a
            href="https://pt.wikipedia.org/wiki/Gera%C3%A7%C3%A3o_Z"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            {{ t("generation_z") }}
          </a>
        </template>
      </i18n-t>
      <p class="mt-2">
        {{ t("neagle_bank_p_3") }}
      </p>
      <p class="mt-2">
        {{ t("neagle_bank_p_4") }}
      </p>
      <p class="mt-2">
        {{ t("neagle_bank_p_5") }}
      </p>
      <p class="mt-2">
        {{ t("neagle_bank_p_6") }}
      </p>
      <i18n-t keypath="neagle_bank_p_7" tag="p" class="mt-2">
        <template #neagleCard>
          <a
            href="https://neaglebank.com/neaglecard"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            NeagleCard
          </a>
        </template>
      </i18n-t>
    </div>
    <div class="card-adjustable w-11/12 md:w-4/12">
      <h3 class="text-xl text-center text-gray-900 my-4">
        {{ t("technologies") }}
      </h3>
      <ul class="text-center space-y-2">
        <li>
          <a
            href="https://reactnative.dev/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            React Native
          </a>
        </li>
        <li>
          <a
            href="https://rubyonrails.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Ruby On Rails
          </a>
        </li>
        <li>
          <a
            href="https://nodejs.org/en/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Node.js
          </a>
        </li>
        <li>
          <a
            href="https://www.postgresql.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            PostgreSQL
          </a>
        </li>
      </ul>
    </div>
    <div v-if="isModalOpen" class="modal">
      <img
        src="../../assets/svgs/x-white.svg"
        alt="Fechar apresentação de imagens"
        class="h-10 cursor-pointer m-4 drag-none"
        @click="isModalOpen = false"
      />
      <img
        v-if="selectedImage === 1"
        src="../../assets/images/neagle/screenshot_1.png"
        alt="Screenshot 1"
        class="modal-image drag-none"
      />
      <img
        v-if="selectedImage === 2"
        src="../../assets/images/neagle/screenshot_2.png"
        alt="Screenshot 2"
        class="modal-image drag-none"
      />
      <img
        v-if="selectedImage === 3"
        src="../../assets/images/neagle/screenshot_3.png"
        alt="Screenshot 3"
        class="modal-image drag-none"
      />
      <img
        v-if="selectedImage === 4"
        src="../../assets/images/neagle/screenshot_4.png"
        alt="Screenshot 4"
        class="modal-image drag-none"
      />
      <img
        v-if="selectedImage === 5"
        src="../../assets/images/neagle/screenshot_5.png"
        alt="Screenshot 5"
        class="modal-image drag-none"
      />
    </div>
    <p class="text-center my-8">
      <router-link to="/" class="link">{{ t("back") }}</router-link>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "IntranetEDS",
  setup: () => {
    const { t, locale } = useI18n();

    const isModalOpen = ref(false);
    const selectedImage = ref(0);

    return { t, locale, isModalOpen, selectedImage };
  },
  mounted: () => {
    document.title = "Elf Technologies - Neagle Bank";
  },
  methods: {
    selectImage(selectedImage: number) {
      this.selectedImage = selectedImage;
      this.isModalOpen = true;
    },
  },
});
</script>
