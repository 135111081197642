import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import IntranetEDS from "../views/projects/IntranetEDS.vue";
import NeagleBank from "../views/projects/NeagleBank.vue";
import Outros from "../views/projects/Outros.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/projetos/intranet-eds",
    name: "IntranetEDS",
    component: IntranetEDS,
  },
  {
    path: "/projetos/neagle-bank",
    name: "NeagleBank",
    component: NeagleBank,
  },
  {
    path: "/projetos/outros",
    name: "Outros",
    component: Outros,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
