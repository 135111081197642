<template>
  <div class="bg-gray-50">
    <router-link to="/">
      <h1 class="text-3xl text-center text-gray-900 pt-4">Elf Technologies</h1>
    </router-link>
    <router-view />
    <div class="flex-1"></div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Footer from "./components/Footer.vue";

export default defineComponent({
  name: "App",
  components: {
    Footer,
  },
});
</script>

<style lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app {
  height: 100%;
}
#app,
#app > div,
#app > div > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.drag-none {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.link {
  @apply text-teal-500 hover:text-teal-400 visited:text-teal-600;
}
.card {
  @apply bg-white mt-4 p-4 w-11/12 xl:w-8/12 2xl:w-7/12 mx-auto shadow-md rounded-md;
}
.card-adjustable {
  @apply bg-white mt-4 p-4 mx-auto shadow-md rounded-md;
}
.gallery {
  @apply flex justify-between overflow-x-auto whitespace-nowrap my-8;
}
.gallery-item {
  @apply h-32 whitespace-nowrap mx-4 cursor-pointer;
}
.modal {
  @apply fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80;
}
.modal-image {
  @apply h-3/4 mx-auto object-contain;
}
</style>
