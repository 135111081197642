
import { defineComponent } from "vue";
import Footer from "./components/Footer.vue";

export default defineComponent({
  name: "App",
  components: {
    Footer,
  },
});
