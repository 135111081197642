<template>
  <div class="home">
    <div class="w-12 mx-auto my-2">
      <div class="flex justify-between">
        <img
          src="../assets/svgs/br.svg"
          alt="Ver Página em Português do Brasil"
          class="h-4 cursor-pointer"
          @click="locale = 'pt-BR'"
        />
        <img
          src="../assets/svgs/gb.svg"
          alt="View Page in English"
          class="h-4 cursor-pointer"
          @click="locale = 'en'"
        />
      </div>
    </div>
    <div class="card text-center">
      <img
        src="../assets/images/elias.png"
        alt="Elias Feijó"
        class="h-auto w-40 rounded-full mx-auto my-4"
      />
      <i18n-t keypath="home_h2" tag="h2" class="text-lg">
        <template #name>
          <strong>Elias Feijó</strong>
        </template>
        <template #age>
          <span>28</span>
        </template>
      </i18n-t>
      <p class="mt-2">
        {{ t("home_p_1") }}
      </p>
      <p class="mt-2">
        {{ t("home_p_2") }}
      </p>
      <p class="mt-2">
        {{ t("home_p_3") }}
        <br />
        <a href="mailto:eliasfeijo.dev@gmail.com" class="link">
          eliasfeijo.dev@gmail.com
        </a>
      </p>
      <p class="mt-2">{{ t("home_p_4") }}:</p>
      <p class="mt-2 flex justify-center">
        <a
          href="https://www.linkedin.com/in/elias-feij%C3%B3-a5169a1b1"
          target="_blank"
          rel="noopener"
          class="link flex items-center"
        >
          <img
            src="../assets/images/linkedin.png"
            alt="LinkedIn Elias Feijó"
            class="h-4"
          />
          <span class="ml-1">LinkedIn</span>
        </a>
      </p>
      <p class="mt-2 flex items-center justify-center">
        <a
          href="https://github.com/eliasfeijo"
          target="_blank"
          rel="noopener"
          class="link flex items-center"
        >
          <img
            src="../assets/images/github.png"
            alt="GitHub Elias Feijó"
            class="h-4"
          />
          <span class="ml-1">GitHub</span>
        </a>
      </p>
      <p class="mt-2 flex items-center justify-center">
        <a
          href="https://techhub.social/@eliasfeijo"
          target="_blank"
          rel="me noopener"
          class="link flex items-center"
        >
          <img
            src="../assets/svgs/mastodon.svg"
            alt="Mastodon Elias Feijó"
            class="h-6"
          />
          <span class="ml-1">Mastodon</span>
        </a>
      </p>
    </div>
    <div class="card-adjustable w-11/12 md:w-6/12">
      <h3 class="text-2xl text-center">{{ t("home_h3") }}</h3>
      <ul class="mt-2 space-y-2 text-center">
        <li>
          <router-link to="/projetos/intranet-eds" class="link">
            {{ t("intranet_eds") }}
          </router-link>
        </li>
        <li>
          <router-link to="/projetos/neagle-bank" class="link">
            {{ t("neagle_bank") }}
          </router-link>
        </li>
        <li>
          <router-link to="/projetos/outros" class="link">
            {{ t("others") }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Home",
  setup: () => {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  mounted: () => {
    document.title = "Elf Technologies";
  },
});
</script>
