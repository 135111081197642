<template>
  <div class="others bg-gray-50">
    <div class="w-12 mx-auto my-2">
      <div class="flex justify-between">
        <img
          src="../../assets/svgs/br.svg"
          alt="Ver Página em Português do Brasil"
          class="h-4 cursor-pointer"
          @click="locale = 'pt-BR'"
        />
        <img
          src="../../assets/svgs/gb.svg"
          alt="View Page in English"
          class="h-4 cursor-pointer"
          @click="locale = 'en'"
        />
      </div>
    </div>
    <div class="card">
      <h2 class="text-2xl text-center text-gray-900 my-4">
        {{ t("others_h2") }}
      </h2>
      <p class="mt-2">
        {{ t("others_p_1") }}
      </p>
    </div>
    <div class="card">
      <h3 class="text-xl my-4">
        {{ t("others_h3_1") }} site (elftechnologies.com)
      </h3>
      <h4 class="text-lg my-4">{{ t("about") }}</h4>
      <p class="mt-2">
        {{ t("others_p_2") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_3") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_4") }}
      </p>
      <i18n-t keypath="others_p_5" tag="p" class="mt-2">
        <template #netlify>
          <a
            href="http://netlify.com"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >Netlify</a
          >
        </template>
      </i18n-t>
      <h4 class="text-lg my-4">{{ t("technologies") }}</h4>
      <ul class="space-y-2">
        <li>
          <a
            href="https://vuejs.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Vue.js V3
          </a>
        </li>
        <li>
          <a
            href="https://tailwindcss.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Tailwind CSS
          </a>
        </li>
        <li>
          <a
            href="https://www.typescriptlang.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            TypeScript
          </a>
        </li>
        <li>
          <a
            href="https://nodejs.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Node.js
          </a>
        </li>
      </ul>
    </div>
    <div class="card">
      <h3 class="text-xl my-4">Blocktrends</h3>
      <p class="mt-2">
        {{ t("others_p_6") }}
      </p>
      <a
        href="https://hungry-mahavira-88e434.netlify.app/"
        target="_blank"
        rel="noopener noreferrer"
        class="mt-2 flex cursor-pointer link"
      >
        <img
          src="../../assets/svgs/play.svg"
          :alt="t('view_demo')"
          class="h-6"
        />
        <span class="ml-2">{{ t("view_demo") }}</span>
      </a>
      <i18n-t keypath="design_by_bressan" tag="p" class="mt-2">
        <template #bressan>
          <a
            href="https://www.linkedin.com/in/rafaelbressan/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >Rafael Bressan</a
          >
        </template>
      </i18n-t>
      <h4 class="text-lg my-4">{{ t("about") }}</h4>
      <p class="mt-2">
        {{ t("others_p_7") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_8") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_9") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_10") }}
      </p>
      <h4 class="text-lg my-4">{{ t("technologies") }}</h4>
      <ul class="space-y-2">
        <li>
          <a
            href="https://nuxtjs.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Nuxt.js
          </a>
        </li>
        <li>
          <a
            href="https://bulma.io/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Bulma
          </a>
        </li>
        <li>
          <a
            href="https://sass-lang.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Sass
          </a>
        </li>
        <li>
          <a
            href="https://nodejs.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Node.js
          </a>
        </li>
      </ul>
    </div>
    <div class="card">
      <h3 class="text-xl my-4">{{ t("others_h3_2") }}</h3>
      <p class="mt-2">
        {{ t("others_p_11") }}
      </p>
      <a
        href="https://zen-raman-b2e1be.netlify.app/"
        target="_blank"
        rel="noopener noreferrer"
        class="mt-2 flex cursor-pointer link"
      >
        <img
          src="../../assets/svgs/play.svg"
          :alt="t('view_demo')"
          class="h-6"
        />
        <span class="ml-2">{{ t("view_demo") }}</span>
      </a>
      <i18n-t keypath="design_by_bressan" tag="p" class="mt-2">
        <template #bressan>
          <a
            href="https://www.linkedin.com/in/rafaelbressan/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >Rafael Bressan</a
          >
        </template>
      </i18n-t>
      <h4 class="text-lg my-4">{{ t("about") }}</h4>
      <p class="mt-2">
        {{ t("others_p_12") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_13") }}
      </p>
      <p class="mt-2">
        {{ t("others_p_14") }}
      </p>

      <h4 class="text-lg my-4">{{ t("technologies") }}</h4>
      <ul class="space-y-2">
        <li>
          <a
            href="https://nuxtjs.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Nuxt.js
          </a>
        </li>
        <li>
          <a
            href="https://bulma.io/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Bulma
          </a>
        </li>
        <li>
          <a
            href="https://sass-lang.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Sass
          </a>
        </li>
        <li>
          <a
            href="https://nodejs.org/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            Node.js
          </a>
        </li>
      </ul>
    </div>
    <p class="text-center my-8">
      <router-link to="/" class="link">{{ t("back") }}</router-link>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Outros",
  setup: () => {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  mounted: () => {
    document.title = "Elf Technologies - Outros";
  },
});
</script>
