import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

const app = createApp(App);
app.use(i18n);
app.use(store);
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
app.use(router);
app.mount("#app");
