
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "IntranetEDS",
  setup: () => {
    const { t, locale } = useI18n();

    const isModalOpen = ref(false);
    const selectedImage = ref(0);

    return { t, locale, isModalOpen, selectedImage };
  },
  mounted: () => {
    document.title = "Elf Technologies - Neagle Bank";
  },
  methods: {
    selectImage(selectedImage: number) {
      this.selectedImage = selectedImage;
      this.isModalOpen = true;
    },
  },
});
