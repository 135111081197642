<template>
  <footer class="py-8 bg-gray-200 bg-opacity-60">
    <p class="text-center">
      <strong>Elias Feijó</strong>. {{ new Date().getFullYear() }} ©
      {{ t("footer") }}
    </p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Footer",
  setup: () => {
    const { t } = useI18n();
    return { t };
  },
});
</script>
